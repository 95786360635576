import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";

const ROLE_ID_GUEST = 1;
const ROLE_ID_SUPER_ADMIN = 2;
const ROLE_ID_ACCOUNT_OWNER = 3;
const ROLE_ID_ACCOUNT_USER = 4;
const ROLE_ID_COUNTER_OPERATOR = 6;
const ROLE_ID_LOCKER_TECHNICIAN = 7;
const ROLE_ID_BOB_BOX_ADMIN = 8;
const ROLE_ID_BOB_BOX_CUSTOMER_SUPPORT = 9;
const ROLE_ID_BOB_BOX_HARDWARE_ADMIN = 11;

const searchBarPermissions = ["API_/accounts:GET", "API_/invoices:GET", "API_/credit-notes:GET"];

const accountSpecificRoles = [
  {
    label: "Account User",
    value: 4
  },
  {
    label: "Account Superuser",
    value: 3
  }
];

function hasRoleID(user: IUser | undefined, rolesToContain: number[]): boolean {
  return Boolean(user && rolesToContain.includes(user.role_id));
}

function isAccountOwner(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && hasRoleID(activeUser, [ROLE_ID_ACCOUNT_OWNER])
  );
}

function isAccountUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && hasRoleID(activeUser, [ROLE_ID_ACCOUNT_USER])
  );
}

function allStaffRoles(roles: any[], activeUser: IUser | undefined): any[] {
  let filteredRoles = roles.filter(
    role =>
      role.id !== ROLE_ID_ACCOUNT_USER &&
      role.id !== ROLE_ID_ACCOUNT_OWNER &&
      role.id !== ROLE_ID_GUEST
  );

  if (!isSuperAdmin(activeUser)) {
    filteredRoles = filteredRoles.filter(role => role.value !== ROLE_ID_SUPER_ADMIN);
  }

  return filteredRoles;
}

function isStaff(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && !activeUser.account_id) && !hasRoleID(activeUser, [ROLE_ID_GUEST]);
}

function isSuperAdmin(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && hasRoleID(activeUser, [ROLE_ID_SUPER_ADMIN]));
}

function isAccountUserOrSuper(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser &&
      activeUser.account_id &&
      hasRoleID(activeUser, [ROLE_ID_ACCOUNT_USER, ROLE_ID_ACCOUNT_OWNER])
  );
}

function userHasAdminSystemAccess(props: { store: any }) {
  const { store } = props;
  const activeUser = utils.activeUserFromStore(store);
  if (activeUser && !activeUser.account_id && isStaff(activeUser)) {
    return true;
  }
  return false;
}

function isBobBoxCounterOperator(role: { id: number }): boolean {
  return role.id === ROLE_ID_COUNTER_OPERATOR;
}

function isBobBoxLockerTechnician(role: { id: number }): boolean {
  return role.id === ROLE_ID_LOCKER_TECHNICIAN;
}

function isBobBoxAdmin(role: { id: number }): boolean {
  return role.id === ROLE_ID_BOB_BOX_ADMIN;
}

function isBobBoxHardwareAdmin(role: { id: number }): boolean {
  return role.id === ROLE_ID_BOB_BOX_HARDWARE_ADMIN;
}

function isBobBoxCustomerSupportUser(role: { id: number }): boolean {
  return role.id === ROLE_ID_BOB_BOX_CUSTOMER_SUPPORT;
}

function isBobBoxUser(activeUser: IUser): boolean {
  if (!activeUser || !activeUser.role) return false;

  const { role } = activeUser;
  return (
    isBobBoxCounterOperator(role) ||
    isBobBoxLockerTechnician(role) ||
    isBobBoxAdmin(role) ||
    isBobBoxCustomerSupportUser(role) ||
    isBobBoxHardwareAdmin(role)
  );
}

export {
  isBobBoxCustomerSupportUser,
  isBobBoxAdmin,
  isBobBoxUser,
  isBobBoxCounterOperator,
  isBobBoxLockerTechnician,
  isBobBoxHardwareAdmin,
  allStaffRoles,
  userHasAdminSystemAccess,
  isAccountOwner,
  isAccountUser,
  isAccountUserOrSuper,
  isSuperAdmin,
  isStaff,
  accountSpecificRoles,
  searchBarPermissions,
  ROLE_ID_SUPER_ADMIN
};
