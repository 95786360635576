import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as localRoleUtils from "utils/roleUtils";
import { withRouter } from "react-router-dom";
import {
  NavItem,
  NavItemDivider,
  navUtils,
  interfaces,
  roleUtils,
  withStore
} from "bob-group-ui-framework";
import { withErrorBoundary } from "errorBoundary";

interface IProps {
  onMounted: any;
  history: any;
  store: any;
  closeMenu: any;
  isNavbarCollapsed: any;
  activeUser: any;
  toggleNavbarCollapsed: any;
  hasBobBoxTheme: boolean;
}

interface INavSection {
  sectionHeading?: string;
  items: interfaces.INavItem[];
}

function AdminNavigation(props: IProps) {
  const { store, isNavbarCollapsed, activeUser, hasBobBoxTheme } = props;

  useEffect(() => {
    props.onMounted();
  }, []);

  function getNavSections(): INavSection[] {
    const isBobBoxCounterOperatorUser =
      activeUser && localRoleUtils.isBobBoxCounterOperator(activeUser.role);

    const navSections: INavSection[] = [];

    if (isBobBoxCounterOperatorUser) {
      return [
        {
          items: [
            {
              displayName: isBobBoxCounterOperatorUser ? "Shipments" : "Bob Box",
              icon: "box",
              path: isBobBoxCounterOperatorUser ? "/shipments" : "/bob-box",
              hasAccess: roleUtils.hasPermission(
                activeUser,
                "API_/bob-box/locations/reservations:GET"
              ),
              displayCondition: true
            }
          ]
        }
      ];
    }

    navSections.push({
      items: [
        {
          displayName: "Accounts",
          icon: "star",
          path: "/accounts",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/accounts:PATCH"),
          displayCondition: true
        },
        {
          displayName: "Users",
          icon: "users",
          path: "/users",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/users:POST"),
          displayCondition: true
        }
      ]
    });

    navSections.push({
      sectionHeading: "Bob Box",
      items: [
        {
          displayName: "Dashboard",
          icon: "chart-line",
          path: "/bob-box/dashboard",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/bob-box/dashboard:GET"),
          displayCondition: true
        },
        {
          displayName: "Locations",
          icon: "map-marker-alt",
          path: "/bob-box/locations",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/bob-box/locations:GET"),
          displayCondition: true
        },
        {
          displayName: "Reservations",
          icon: "box",
          path: "/bob-box/reservations",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/bob-box/locations/reservations:GET"),
          displayCondition: true
        },
        {
          displayName: "Broken doors",
          icon: "exclamation-triangle",
          path: "/bob-box/broken-doors",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/bob-box/locations/reservations:GET"),
          displayCondition: true
        }
      ]
    });

    if (!hasBobBoxTheme) {
      navSections.push({
        sectionHeading: "Bob Shop",
        items: [
          {
            displayName: "Bob Play",
            icon: "play",
            path: "/play/campaigns",
            hasAccess: roleUtils.hasPermission(activeUser, "API_/play/campaigns:POST"),
            displayCondition: true
          },
          {
            displayName: "Clever Bob",
            icon: "brain",
            path: "/clever-bob",
            hasAccess: roleUtils.hasPermission(activeUser, "API_/ai/categorization:GET"),
            displayCondition: true
          }
        ]
      });

      navSections.push({
        sectionHeading: "Utilities",
        items: [
          {
            displayName: "URL shortener",
            icon: "globe",
            path: "/short-urls",
            hasAccess: roleUtils.hasPermission(activeUser, "API_/short-urls:POST"),
            displayCondition: true
          },
          {
            displayName: "Push notifications",
            icon: "bell",
            path: "/push-notifications",
            hasAccess: roleUtils.hasPermission(
              activeUser,
              "API_/push-notifications/campaigns:POST"
            ),
            displayCondition: true
          },
          {
            displayName: "Secure Citizen",
            icon: "shield",
            path: "/secure-citizen",
            hasAccess: roleUtils.hasPermission(activeUser, "API_/ai/kyc/requests:GET"),
            displayCondition: true
          },
          {
            displayName: "Tools",
            icon: "tools",
            path: "/tools",
            hasAccess: true,
            displayCondition: roleUtils.hasAnyPermission(activeUser, [
              "API_/addresses/geocode:PATCH",
              "API_/addresses/geocode/explore:POST",
              "API_/sage/import:POST"
            ])
          }
        ]
      });

      navSections.push({
        items: [
          {
            displayName: "System config",
            icon: "cog",
            path: "/system-config",
            hasAccess: roleUtils.hasPermission(activeUser, "API_/config:POST"),
            displayCondition: true
          }
        ]
      });
    }

    return navSections;
  }

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderNavSectionItems(section: any[]) {
    return section.map((item: any, itemIndex: number) => {
      return (
        <Link to={item.path} onClick={() => props.closeMenu()} key={itemIndex}>
          <NavItem
            item={item}
            isNavbarCollapsed={props.isNavbarCollapsed}
            bgColor={hasBobBoxTheme ? undefined : "bg-primary-leftNav"}
            textColor={hasBobBoxTheme ? undefined : "text-white"}
            hoverBgColor={hasBobBoxTheme ? undefined : "hover:bg-primary-leftNav"}
          />
        </Link>
      );
    });
  }

  function renderNavSections() {
    const navSections = getNavSections();

    return navSections.map((section, sectionIndex) => {
      const sectionItemsWithAccess = section.items.filter(
        item => item.hasAccess && item.displayCondition
      );

      if (sectionItemsWithAccess.length === 0) return null;

      const displayBreak =
        sectionIndex < navSections.length - 1 &&
        sectionItemsWithAccess &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1].items); // If user has access to both current and next section, add <NavItemDivider />

      return (
        <div key={sectionIndex} className="nav-sections">
          {section.sectionHeading && !isNavbarCollapsed && (
            <div
              className={`uppercase text-sm py-1 ${
                hasBobBoxTheme ? "text-gray" : "text-primary-light"
              }`}
            >
              {section.sectionHeading}
            </div>
          )}
          {renderNavSectionItems(section.items)}
          {displayBreak ? <NavItemDivider /> : null}
        </div>
      );
    });
  }

  function render() {
    if (!store) return null;

    return renderNavSections();
  }

  return render();
}

export default withStore(withRouter(withErrorBoundary(AdminNavigation)));
